<template lang="">
      <Design/>
</template>
<script>
export default {
  components: {
    Design: () => import("@/components/adverts/Design.vue"),
  },
};
</script>
